import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './StackTraceBanner.module.scss';

interface IStackTraceBanner {
  jobId: string;
  stepName: string;
  timestamp: string;
}

export const StackTraceBanner = ({
  jobId,
  stepName,
  timestamp,
}: IStackTraceBanner) => {
  const parseStepName = (stepName: string) => {
    const [index, ...nameParts] = stepName.split(' ');
    const indexNumber = parseInt(index, 10);
    const name = nameParts.join(' ');

    if (isNaN(indexNumber)) {
      return { index: null, name: stepName };
    }

    return { index: indexNumber, name };
  };

  const { index, name } = parseStepName(stepName);

  return (
    <CsbErrorBoundary>
      <section className={style['container']} data-testid="StackTraceBanner">
        <div className={style['title']}>
          <span className={style['jobId']}>Job ID {jobId}</span>
          <span className={style['step']}>{index ? `Step ${index}` : ''}</span>
          <span className={style['stepName']}>{name}</span>
          <span className={style['timestamp']}>Start time: {timestamp}</span>
          <span className={style['stackTrace']}>Stack Trace Data</span>
        </div>
      </section>
    </CsbErrorBoundary>
  );
};
