import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Tabs, TabHeader, TabPanel } from '@wk/components-react16';
import CreateModuleNormalTab from './components/CreateModuleNormalTab/CreateModuleNormalTab';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { CREATE_MODULE_TABS } from 'utils/common-constants';
import style from './CreateModuleModal.module.scss';
import CreateModuleSourceTab from './components/CreateModuleSourceTab/CreateModuleSourceTab';
import { generateModuleBlob } from 'api/manageModules/manageModulesThunks';
import {
  formatModuleData,
  parseParametersXML,
  parsePomXML,
  sortParameters,
  findFirstGroupIdOutsideParent,
} from 'components/Modals/utils/createModuleModal/utils';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectParameter,
  selectParameterList,
  selectSelectedParameter,
  setParameterList,
  setPomContent,
  setManifestContent,
  selectPomContent,
  selectManifestContent,
  setCurrentTab,
} from 'redux/createModule/CreateModuleSlice';
import {
  IModuleParameterItem,
  ICreateModuleOverviewNames,
} from 'interfaces/modules/module.interface';
import { useState } from 'react';
import { OverlayComponent } from 'components/OverlayComponent/OverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { showToastAndClose } from 'utils/commonFunctions/CommonFunctions';

const requiredFields: ICreateModuleOverviewNames[] = [
  'moduleName',
  'artifactId',
  'description',
  'authorName',
  'email',
];

interface ICreateModuleModal {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const CreateModuleModal = ({
  register,
  setValue,
  setError,
  clearErrors,
  watch,
  errors,
}: ICreateModuleModal) => {
  const paramListData: IModuleParameterItem[] =
    useAppSelector(selectParameterList);
  const selectedParam: IModuleParameterItem = useAppSelector(
    selectSelectedParameter
  );
  const pomContent = useAppSelector(selectPomContent);
  const manifestContent = useAppSelector(selectManifestContent);
  const dispatch = useAppDispatch();
  const moduleDataFields = watch(requiredFields);
  const groupIdField = watch('groupId');
  const [isLoading, setIsLoading] = useState(false);

  const fetchXmlCode = async () => {
    setIsLoading(true);
    try {
      dispatch(setCurrentTab(1));
      const moduleInformation = {
        moduleName: moduleDataFields?.[0] ?? '',
        artifactId: moduleDataFields?.[1] ?? '',
        description: moduleDataFields?.[2] ?? '',
        authorName: moduleDataFields?.[3] ?? '',
        email: moduleDataFields?.[4] ?? '',
      };
      const modulePayload = formatModuleData(moduleInformation, paramListData, {
        useDefaultEmptyString: true,
      });

      const response = await dispatch(
        generateModuleBlob(modulePayload)
      ).unwrap();
      setIsLoading(false);
      dispatch(setPomContent(response.pomContent));
      dispatch(setManifestContent(response.manifestContent));
      if (groupIdField === '') {
        const generatedGroupId = findFirstGroupIdOutsideParent(
          response.pomContent
        );
        setValue('groupId', generatedGroupId);
      }
    } catch (error) {
      console.error('Error fetching XML code:', error);
      setIsLoading(false);
      showToastAndClose('error', 'informationToast', () => {}, dispatch, {
        text:
          (error as any)?.message ??
          'there was an error while creating the XML',
      });
    }
  };

  const handleParseXml = async () => {
    try {
      dispatch(setCurrentTab(0));
      const moduleParameters = await parseParametersXML(manifestContent);
      const moduleDataParsed = parsePomXML(pomContent);
      if (moduleDataParsed) {
        Object.entries(moduleDataParsed).forEach(([key, value]) => {
          if (key === 'groupId' && groupIdField !== '') {
            return;
          }
          setValue(key, value);
        });
      }
      if (moduleParameters) {
        const sortedParameters = sortParameters(
          moduleParameters,
          paramListData
        );
        dispatch(setParameterList(sortedParameters));
        const prevSelectedParamParamId = selectedParam?.paramId;
        const selectedParamExists = moduleParameters.find(
          (param) =>
            param.paramId === prevSelectedParamParamId ||
            param.paramName === selectedParam.paramName
        );
        dispatch(selectParameter(selectedParamExists || null));
      }
    } catch (error) {
      console.error('Error parsing manifest content:', error);
      showToastAndClose('error', 'informationToast', () => {}, dispatch, {
        text:
          (error as any)?.message ??
          'there was an error while parsing back the XML',
      });
    }
  };

  const handleTabChange = async (event: number) => {
    if (event === 0) {
      handleParseXml();
    } else if (event === 1) {
      fetchXmlCode();
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="CreateModuleModal"
        className={style['module-modal-content']}
      >
        <Tabs
          mode="classic"
          onUserRequest={(event: number) => handleTabChange(event)}
        >
          {CREATE_MODULE_TABS.map(({ id, label }) => (
            <TabHeader key={id} slot="tabHeader">
              {label}
            </TabHeader>
          ))}
          <TabPanel data-testid="CreateModuleFirstTabPanel" slot="tabPanel">
            <CreateModuleNormalTab
              register={register}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
            />
          </TabPanel>
          <TabPanel data-testid="CreateModuleSecondTabPanel" slot="tabPanel">
            {isLoading && (
              <OverlayComponent
                page={PAGE_OPTIONS.commonLoader}
                style="fixed"
                border={true}
              />
            )}
            <CreateModuleSourceTab />
          </TabPanel>
        </Tabs>
      </div>
    </CsbErrorBoundary>
  );
};

export default CreateModuleModal;
