import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { CancelTokenSource, isCancel } from 'axios';
import { IOrchestrationsServerResponse } from 'interfaces/dashboard/dashboardSlice.interface';
import { ILogFromServer } from 'interfaces/logRow.interface';
import { IOrchestrationServer } from 'interfaces/orchestration.interface';
import { IPostJobsInfo } from 'interfaces/postJobsInfo.interface';
import { uuidSignal } from 'utils/hooks/useSSE';
import { handleThunkError } from 'utils/thunkErrorHandler';

const BASE_URL = '/csb/jobs';
let cancelTokenSource: CancelTokenSource | null = null;

export const fetchAllOrchestrations = createAsyncThunk(
  'dashboardContainer/fetchAllOrchestrations',
  async (data: IPostJobsInfo, { rejectWithValue }) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Canceled previous request');
    }
    cancelTokenSource = axios.CancelToken.source();
    try {
      const response = await axios.post<IOrchestrationsServerResponse>(
        `${BASE_URL}/sse/${uuidSignal}`,
        data,
        {
          cancelToken: cancelTokenSource.token,
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (isCancel(error)) {
        return null;
      }
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchAllOrchestrationsNoLoading = createAsyncThunk(
  'dashboardContainer/fetchAllOrchestrationsNoLoading',
  async (data: IPostJobsInfo, { rejectWithValue }) => {
    try {
      const response = await axios.post<IOrchestrationsServerResponse>(
        `${BASE_URL}/sse/${uuidSignal}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const getOrchestrationById = createAsyncThunk(
  'dashboardContainer/getOrchestrationById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IOrchestrationServer>(
        `${BASE_URL}/${id}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const postViewLog = createAsyncThunk(
  'dashboardContainer/postViewLog',
  async (data: Object, { rejectWithValue }) => {
    try {
      const response = await axios.post<ILogFromServer[]>(
        `${BASE_URL}/log`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);
