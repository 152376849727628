import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './CellLogMessage.module.scss';
import { ILogRow } from 'interfaces/logRow.interface';
import { Icon } from '@wk/components-v3-react16';

interface ICellLogMessage {
  data: ILogRow;
}

export const CellLogMessage = ({ data }: ICellLogMessage) => {
  const handleOpenStackTrace = () => {
    window.open(
      `/stack-trace?jobId=${data.metadata?.CSBJobId || ''}&stepName=${
        data['Step name'] || ''
      }&timestamp=${data.createdTime}&index=${data.index}&originalStartTime=${
        data.overallCreatedTime
      }`,
      '_blank'
    );
  };

  return (
    <CsbErrorBoundary>
      <div className={style['cell-container']} data-testid="CellLogMessage">
        {data?.message && (
          <span className={style['cell-message']}> {data?.message}</span>
        )}
        {data?.hasStackTrace && (
          <div
            className={style['cell-link']}
            onClick={() => handleOpenStackTrace()}
            data-testid="CellLogMessageLink"
          >
            <span>View Stack Trace Data</span>
            <Icon name="wk-icon-link-external" />
          </div>
        )}
      </div>
    </CsbErrorBoundary>
  );
};
