import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-xml';
import useDynamicHeight from '../../hooks/useDynamicHeight';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setPomContent,
  selectPomContent,
} from 'redux/createModule/CreateModuleSlice';

const PomSourceCode = () => {
  const dispatch = useAppDispatch();
  const pomContent = useAppSelector(selectPomContent);
  const heightOffset = useDynamicHeight();
  const editorRef = useRef<AceEditor | null>(null);

  useEffect(() => {
    editorRef.current?.editor?.resize();
  }, [heightOffset]);

  const handleContentChange = (newContent: string) => {
    dispatch(setPomContent(newContent));
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="PomSourceCode"
        style={{
          position: 'absolute',
          height: `${heightOffset}%`,
          width: '95%',
        }}
      >
        <AceEditor
          mode="xml"
          name="pom_editor"
          width="100%"
          value={pomContent}
          height={'100%'}
          showPrintMargin={false}
          onChange={handleContentChange}
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false, wrap: true }}
        />
      </div>
    </CsbErrorBoundary>
  );
};

export default PomSourceCode;
