import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-xml';
import useDynamicHeight from '../../hooks/useDynamicHeight';
import {
  setManifestContent,
  selectManifestContent,
} from 'redux/createModule/CreateModuleSlice';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const ManifestSourceCode = () => {
  const dispatch = useAppDispatch();
  const manifestContent = useAppSelector(selectManifestContent);

  const heightOffset = useDynamicHeight();
  const editorRef = useRef<AceEditor | null>(null);

  useEffect(() => {
    editorRef.current?.editor?.resize();
  }, [heightOffset]);

  const handleContentChange = (newContent: string) => {
    dispatch(setManifestContent(newContent));
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="ManifestSourceCode"
        style={{
          position: 'absolute',
          height: `${heightOffset}%`,
          width: '95%',
        }}
      >
        <AceEditor
          mode="xml"
          name="manifest_editor"
          width="100%"
          value={manifestContent}
          height={`100%`}
          showPrintMargin={false}
          onChange={handleContentChange}
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false, wrap: true }}
        />
      </div>
    </CsbErrorBoundary>
  );
};

export default ManifestSourceCode;
