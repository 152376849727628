import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { IDesignOrchestrationSlice } from 'interfaces/designOrchestration/designOrchestrationState.interface';
import { fetchModulesVersions } from 'api/manageModules/manageModulesThunks';
import { IModuleServer } from 'interfaces/modules/module.interface';
import { serverModules } from 'dto/designOrchestration/modules/serverModules';

export const SLICE_KEY = 'designOrchestration';

const initialState: IDesignOrchestrationSlice = {
  serverModules: [],
  isAccordionOpen: true,
  stepModules: [],
  orchestrationDetails: {
    orchestrationName: undefined,
    orchestrationDescription: undefined,
    businessProduct: undefined,
    throttleRate: undefined,
    cronTrigger: undefined,
    cronTimezone: undefined,
    sftpDropzone: undefined,
    sftpPrivateKey: undefined,
    ftpDropzone: undefined,
    s3Dropzone: undefined,
    notificationEmails: undefined,
    eventParamsMapping: undefined,
    reportingIndex: undefined,
    unpackProduct: undefined,
  },
  loading: false,
  xmlCode: '',
};

const designOrchestrationSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    clearState: (state) => {
      return initialState;
    },
    setOrchestrationDetails: (
      state,
      action: PayloadAction<
        Partial<IDesignOrchestrationSlice['orchestrationDetails']>
      >
    ) => {
      state.orchestrationDetails = {
        ...state.orchestrationDetails,
        ...action.payload,
      };
    },
    setAccordionStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAccordionOpen: action.payload,
      };
    },
    setStepAccordionStatus: (
      state,
      action: PayloadAction<{
        order: number;
        moduleName: string;
        moduleVersion: string;
        isOpen: boolean;
      }>
    ) => {
      return {
        ...state,
        stepModules: state.stepModules?.map((stepModule) => {
          if (
            stepModule.order === action.payload?.order &&
            stepModule.textId === action.payload?.moduleName &&
            stepModule.version === action.payload?.moduleVersion
          ) {
            return { ...stepModule, isOpen: action.payload.isOpen };
          }
          return { ...stepModule };
        }),
      };
    },
    setXmlCode: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        xmlCode: action.payload,
      };
    },
    addStepModule: (
      state,
      action: PayloadAction<{ moduleName: string; moduleVersion: string }>
    ) => {
      const moduleToAdd = state.serverModules.find(
        (module) =>
          module.textId === action.payload.moduleName &&
          module.version === action.payload.moduleVersion
      );
      if (!moduleToAdd) {
        return state;
      }

      const sortedParameters = [...moduleToAdd.parameters]?.sort((a, b) => {
        const aQualified =
          a.type === 'ENUM' &&
          moduleToAdd.parameters?.some((param) => param.dependsId === a.textId);
        const bQualified =
          b.type === 'ENUM' &&
          moduleToAdd.parameters?.some((param) => param.dependsId === b.textId);

        if (aQualified && !bQualified) {
          return -1;
        }
        if (!aQualified && bQualified) {
          return 1;
        }
        {
          return 0;
        }
      });

      return {
        ...state,
        stepModules: [
          ...state.stepModules,
          {
            ...moduleToAdd,
            parameters: sortedParameters,
            order: state.stepModules.length + 1,
            isOpen: true,
          },
        ],
      };
    },
    updateStepModuleField: (
      state,
      action: PayloadAction<{
        moduleName: string;
        moduleVersion: string;
        order?: number;
        parameterTextId: string;
        value: string;
      }>
    ) => {
      const { moduleName, moduleVersion, order, value, parameterTextId } =
        action.payload;
      const moduleIndexToUpdate = state.stepModules.findIndex(
        (module) =>
          module.textId === moduleName &&
          module.version === moduleVersion &&
          module.order === order
      );

      if (state.stepModules[moduleIndexToUpdate]?.textId) {
        return {
          ...state,
          stepModules: state.stepModules.map((stepModule, moduleIndex) => {
            if (moduleIndex === moduleIndexToUpdate) {
              const parameterIndexToUpdate = stepModule.parameters.findIndex(
                (parameter) => parameter.textId === parameterTextId
              );

              return {
                ...stepModule,
                parameters: stepModule.parameters.map(
                  (parameter, parameterIndex) => {
                    if (parameterIndex === parameterIndexToUpdate) {
                      return { ...parameter, value };
                    }
                    return { ...parameter };
                  }
                ),
              };
            }
            return { ...stepModule };
          }),
        };
      }
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModulesVersions.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchModulesVersions.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(
      fetchModulesVersions.fulfilled,
      (state, { payload }: { payload: IModuleServer[] }) => {
        return {
          ...state,
          serverModules: serverModules(payload),
          loading: false,
        };
      }
    );
  },
});

export const selectOrchestrationDetails = (state: RootState) =>
  state.designOrchestration.orchestrationDetails;

export const selectAccordionStatus = (state: RootState) =>
  state.designOrchestration.isAccordionOpen;

export const selectModulesVersions = (state: RootState) =>
  state.designOrchestration.serverModules;

export const selectLoading = (state: RootState) =>
  state.designOrchestration.loading;

export const selectXmlCode = (state: RootState) =>
  state.designOrchestration.xmlCode;

export const selectStepModules = (state: RootState) =>
  state.designOrchestration.stepModules;

export const selectStepModulesLength = (state: RootState) =>
  state.designOrchestration.stepModules?.length;

export const {
  clearState,
  setOrchestrationDetails,
  setAccordionStatus,
  setXmlCode,
  addStepModule,
  updateStepModuleField,
  setStepAccordionStatus,
} = designOrchestrationSlice.actions;

export default designOrchestrationSlice.reducer;
