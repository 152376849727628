import { useCallback, useRef } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { fetchAvailableModulesWithoutLoading } from 'api/manageModules/manageModulesThunks';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

const useFetchDataInterval = () => {
  const dispatch = useAppDispatch();
  const retryCount = useRef(0);
  const intervalId = useRef<NodeJS.Timer | null>(null);

  const startModulesFetchingInterval = useCallback(() => {
    if (intervalId.current !== null) {
      return;
    }

    intervalId.current = setInterval(() => {
      if (retryCount.current >= 5) {
        if (intervalId.current) {
          clearInterval(intervalId.current);
          intervalId.current = null;
        }
        return;
      }

      try {
        dispatch(fetchAvailableModulesWithoutLoading());
        retryCount.current += 1;
      } catch (error) {
        consoleErrorMessage(error);
      }
    }, 5000);
  }, [dispatch]);

  const resetInterval = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    retryCount.current = 0;
  }, []);

  return { resetInterval, startModulesFetchingInterval };
};

export default useFetchDataInterval;
