import { IModuleServer } from 'interfaces/modules/module.interface';
import { DESIGN_ORCHESTRATION_MODULE_PARAMETERS_INFO } from 'pages/DesignOrchestration/DesignOrchestration.constants';

export const serverModules = (modules: IModuleServer[]): IModuleServer[] => {
  return modules?.map((module) => {
    const domain = DESIGN_ORCHESTRATION_MODULE_PARAMETERS_INFO?.[module.textId];
    return {
      ...module,
      parameters: module.parameters?.map((param) => {
        if (param.type === 'ENUM') {
          let newDefault = param.default;
          if (
            domain &&
            domain?.[param.textId] &&
            domain?.[param.textId]?.defaultValue !== undefined
          ) {
            newDefault = domain[param.textId].defaultValue ?? '';
          }
          return { ...param, value: newDefault };
        }
        return param;
      }),
    };
  });
};
