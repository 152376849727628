import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import DeployModulesTables from 'components/TableContainer/DeployModulTables/DeployModulesTables';
import { MANAGE_MODULES_DEPLOY_SELECT_SERVERS } from 'pages/ManageModules/ManageModules.constants';
import styles from '../../DeployModuleModal.module.scss';
import { IDeployModuleServerRow } from 'interfaces/modules/module.interface';

interface ISelectServersSection {
  servers: IDeployModuleServerRow[] | undefined;
}

export default function SelectServersSection({
  servers,
}: ISelectServersSection) {
  const sortedServers = (servers ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return (
    <CsbErrorBoundary>
      <div
        className={styles['search-results-container']}
        data-testid="ServerResultsSection"
      >
        <span className={styles['header']}>Select servers</span>
        <span className={styles['subtitle']}>
          Select one or more servers to deploy the selected module to
        </span>
        <DeployModulesTables
          items={
            sortedServers?.length > 0
              ? sortedServers
              : [
                  {
                    id: '',
                    name: '',
                    ec2Server: '',
                    type: 'select-servers',
                    register: () => {},
                  },
                ]
          }
          type="server-table"
          columns={MANAGE_MODULES_DEPLOY_SELECT_SERVERS}
        />
      </div>
    </CsbErrorBoundary>
  );
}
