import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import { ColDef, GridApi } from 'ag-grid-enterprise';
import { objectFromLogData } from 'dto/logModal/logToTable';
import { ILogFromServer, ILogRow } from 'interfaces/logRow.interface';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import style from './LogModal.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { TABLE_FIELDS } from 'utils/common-constants';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { defaultFilterParams } from 'components/TableContainer/utils/filterUtils';
import { LogTable } from 'components/TableContainer/LogTable/LogTable';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestrationRow.interface';
import { postViewLog } from 'api/orchestrations/orchestrationsThunk';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectPropertiesPanel } from 'redux/propertiesPanel/PropertiesPanelSlice';

interface ILogModal {
  data?: IOrchestrationRow;
}

export default function LogModal({ data }: ILogModal) {
  const [rowData, setRowData] = useState<ILogRow[]>([]);
  const orchestrationStepsInfo = useAppSelector(selectPropertiesPanel);
  let sortingDirection: any = null;
  const CREATED_TIME = 'Created time';
  const logRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'Step name',
      rowGroup: true,
      hide: true,
    },
    {
      field: TABLE_FIELDS.createdTime,
      minWidth: 200,
      suppressSizeToFit: true,
      filter: 'agDateColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        ...defaultFilterParams,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = cellValue;

          if (dateAsString == null) {
            return 0;
          }

          // dates are stored as mm/dd/yyyy
          const dateParts = dateAsString
            .slice(0, dateAsString.lastIndexOf(','))
            .split('/');
          const year = Number(dateParts[2]);
          const month = Number(dateParts[0]) - 1;
          const day = Number(dateParts[1]);
          const cellDate = new Date(year, month, day);

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      field: TABLE_FIELDS.message,
      minWidth: 160,
      suppressSizeToFit: false,
      wrapText: true,
      autoHeight: true,
      filter: false,
      menuTabs: [],
      cellRenderer: 'cellLogMessage',
    },
    {
      field: TABLE_FIELDS.severity,
      minWidth: 160,
      cellRenderer: 'errorLogCellStatus',
      cellClass: 'csb-centered-cell csb-visible-cell',
      suppressSizeToFit: true,
      filter: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['reset'],
      },
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Step name',
      minWidth: 180,
      sortable: true,
      suppressSizeToFit: true,
      filter: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        ...defaultFilterParams,
      },
      filterValueGetter: (params: any) => params.data['Step name'],
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  useEffect(() => {
    fetchLogInfo();
    changeParentOverflowStyle();
  }, []);

  const changeParentOverflowStyle = () => {
    if (logRef?.current?.parentElement) {
      logRef.current.parentElement.style.overflowY = 'hidden';
    }
  };

  const fetchLogInfo = async () => {
    try {
      const logInfo: ILogFromServer[] = await dispatch(
        postViewLog({
          CSBJobId: data?.id,
          fromTimeCSB: data?.originalStartTime,
        })
      )?.unwrap();
      const logRowObject = objectFromLogData(
        logInfo,
        orchestrationStepsInfo,
        data?.originalStartTime
      );
      setRowData(logRowObject);
      document.dispatchEvent(
        new CustomEvent('logInfoFetched', { detail: { id: data?.id } })
      );
    } catch (error) {
      consoleErrorMessage(error);
      document.dispatchEvent(
        new CustomEvent('logInfoFetched', { detail: { id: data?.id } })
      );
    }
  };

  const openSeverityErrorRows = (
    gridApiRef: MutableRefObject<GridApi | null>
  ) => {
    const errorRowsIndex: number[] = [];
    gridApiRef.current?.forEachLeafNode((row: any) => {
      const parentIndex = row.parent?.rowIndex;
      if (
        row.data['severity'] === 'ERROR' &&
        parentIndex != null &&
        !errorRowsIndex.includes(parentIndex)
      ) {
        errorRowsIndex.push(parentIndex);
      }
    });
    setTimeout(
      () =>
        errorRowsIndex.map((index: number) => {
          gridApiRef.current?.getDisplayedRowAtIndex(index)?.setExpanded(true);
        }),
      100
    );
  };

  const sortAccordionRows = (rowNodes: any[]) => {
    if (
      rowNodes?.[0]?.key &&
      rowNodes?.[0]?.['allLeafChildren']?.[0]?.['data']?.[CREATED_TIME]
    ) {
      if (sortingDirection != null) {
        if (sortingDirection === 'asc') {
          rowNodes.sort(
            (a, b) =>
              getMaxMinValue(a?.['allLeafChildren'], 'min') -
              getMaxMinValue(b?.['allLeafChildren'], 'min')
          );
          sortingDirection = 'desc';
        } else {
          rowNodes.sort(
            (a, b) =>
              getMaxMinValue(b?.['allLeafChildren'], 'max') -
              getMaxMinValue(a?.['allLeafChildren'], 'max')
          );
          sortingDirection = null;
        }
      } else {
        sortingDirection = 'asc';
      }
    }
  };

  const getMaxMinValue = (childrens: any[], value: 'max' | 'min') => {
    if (value === 'max') {
      return Math.max.apply(
        Math,
        childrens.map((e: any) => {
          return Date.parse(e.data[CREATED_TIME]);
        })
      );
    } else {
      return Math.min.apply(
        Math,
        childrens.map((e: any) => {
          return Date.parse(e.data[CREATED_TIME]);
        })
      );
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="LogModal"
        className={style['error-log-modal']}
        ref={logRef}
      >
        <LogTable
          items={rowData}
          columns={columnDefs}
          additionalData={{
            autoGroupColumnDef,
            postSort: sortAccordionRows,
          }}
          additionalStyles={'wk-striped-grey'}
          onGridReadyAdditionalMethods={[
            { id: 'openSeverityRows', method: openSeverityErrorRows },
          ]}
          page={PAGE_OPTIONS.logs}
        />
      </div>
    </CsbErrorBoundary>
  );
}
