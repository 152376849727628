import { ISystemModule } from 'interfaces/designOrchestration/designOrchestrationModules.interface';
import { IDesignOrchestrationDetailsContent } from 'interfaces/designOrchestration/designOrchestrationState.interface';

export enum VIEW_OPTIONS {
  SPLIT_VIEW = 'splitView',
  COLUMN_VIEW = 'columnView',
}

export const DESIGN_ORCHESTRATION_BUTTONS_DATA = [
  {
    id: 'save',
    text: 'Save the orchestration template',
  },
];

export const DESIGN_ORCHESTRATION_BUTTONS_LABELS = {
  SAVE: 'Save',
};

export const DESIGN_ORCHESTRATION_DETAILS_CONTENT: IDesignOrchestrationDetailsContent[] =
  [
    {
      label: 'Orchestration name',
      placeholder: 'Enter the orchestration name',
      id: 'orchestrationName',
      maxLength: 255,
      type: 'text',
      tooltip: 'The database constraint for this property is NVARCHAR(255).',
    },
    {
      label: 'Orchestration Description',
      placeholder: 'Describe the orchestration',
      id: 'orchestrationDescription',
      maxLength: 1000,
      type: 'textarea',
      tooltip: `The Orchestration description should be a single sentence beginning with a verb, e.g. "Imports documents to Apollo using Sync Import Service". 

The database constraint for this property is NVARCHAR(1000).`,
    },
    {
      label: 'Business Product',
      placeholder: 'Enter the business product name',
      id: 'businessProduct',
      maxLength: 300,
      type: 'text',
      tooltip: `This field helps distinguish role-specific permissions in BUAdmin. 

The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'Throttle Rate',
      placeholder: 'Enter an integer or leave blank',
      id: 'throttleRate',
      maxLength: 300,
      type: 'number',
      tooltip: `The Throttle Rate is the maximum number of product instances that can be executed simultaneously.
  
It must be an integer value or no value.`,
    },
    {
      label: 'Cron Trigger',
      placeholder: 'Enter a valid cron expression like 0 0 * * *',
      id: 'cronTrigger',
      maxLength: 300,
      type: 'text',
      tooltip: `The Cron Trigger defines the timestamp when the product instance should be triggered. 

It should contain a valid Cron expression. 
    
The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'Cron Timezone',
      placeholder: 'Enter a valid timezone according to TimeZone.java',
      id: 'cronTimezone',
      maxLength: 300,
      type: 'text',
      tooltip: `Optional Timezone in which the product instance should be triggered. 

The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'SFTP Dropzone',
      placeholder: 'Enter SFTP dropzone name',
      id: 'sftpDropzone',
      maxLength: 300,
      type: 'text',
      tooltip: `Name of the SFTP dropzone from where the orchestration is triggered when a file is dropped for processing. 
    
The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'SFTP Private Key',
      placeholder: 'Enter SFTP Private Key',
      id: 'sftpPrivateKey',
      maxLength: 300,
      type: 'text',
      tooltip: `The SFTP private key used to authenticate a client when it connects to the SFTP server. 
    
The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'FTP Dropzone',
      placeholder: 'Enter FTP dropzone name',
      id: 'ftpDropzone',
      maxLength: 300,
      type: 'text',
      tooltip: `Name of the FTP dropzone from where the orchestration is triggered when a file is dropped for processing. 
    
The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'S3 Dropzone',
      placeholder: 'Enter S3 dropzone name',
      id: 's3Dropzone',
      maxLength: 300,
      type: 'text',
      tooltip: `Name of the S3 dropzone from where the orchestration is triggered when a file is dropped for processing. 
    
The database constraint for this property is NVARCHAR(300).`,
    },
    {
      label: 'Notification Emails',
      placeholder: 'Enter email addresses to receive notifications',
      id: 'notificationEmails',
      maxLength: 1000,
      type: 'text',
      tooltip: `Enter email addresses that should receive notifications on completion of the product instance. 

Separate email addresses using commas where there is more than one.

The database constraint for this property is NVARCHAR(1000).`,
    },
    {
      label: 'Event Params Mapping',
      placeholder: 'Enter eventParameterMapping string',
      id: 'eventParamsMapping',
      maxLength: 1000,
      type: 'text',
      tooltip: `Used by the Event Service where Event Service parameters are mapped to module parameters. 

The database constraint for this property is NVARCHAR(1000).`,
    },
    {
      label: 'Reporting Index',
      placeholder: 'Enter the reporting index predefined value',
      id: 'reportingIndex',
      maxLength: 100,
      type: 'text',
      tooltip: `Used by the Reporting Service. 

The database constraint for this property is NVARCHAR(100).`,
    },
    {
      label: 'Unpack Product',
      placeholder: 'Select No/Yes',
      id: 'unpackProduct',
      type: 'checkbox',
      tooltip: `No = when we don't have to unpack the input file for this product instance.

Yes = when we have to unpack the input file for this product instance.`,
    },
  ];

export const DESIGN_ORCHESTRATION_SYSTEM_MODULES: Record<
  string,
  ISystemModule
> = {
  'system-aggregator': {
    name: 'system-aggregator',
    iconName: 'wk-icon-contract-angle',
    label: 'Aggregate Batches',
  },
  'system-filter': {
    name: 'system-filter',
    iconName: 'wk-icon-funnel',
    label: 'Filter Contents',
  },
  'system-noop': {
    name: 'system-noop',
    iconName: 'wk-icon-arrow-down',
    label: 'Do Nothing',
  },

  'system-splitter': {
    name: 'system-splitter',
    iconName: 'wk-icon-expand-angle',
    label: 'Split into Batches',
  },
};

export const DESIGN_ORCHESTRATION_MODULE_PARAMETERS_INFO: Record<
  string,
  Record<
    string,
    { placeHolder: string; tooltip: string; defaultValue?: string }
  >
> = {
  compare: {
    compareServiceUrl: {
      defaultValue: '',
      placeHolder: 'Enter the Compare Service endpoint',
      tooltip: `This property stores the Compare Service endpoint URL.
      
This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    compareServicePlan: {
      defaultValue: '',
      placeHolder: 'Enter the Compare Service Plan Name',
      tooltip: `This field stores the Compare Service Plan Name.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
  },
  conversion: {
    endpointOSA3: {
      defaultValue: '',
      placeHolder: 'Enter the Content Conversion Service endpoint',
      tooltip: `This property stores the Content Conversion Service endpoint URL.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    conversionName: {
      defaultValue: '',
      placeHolder: 'Enter the Content Conversion Service Plan Name',
      tooltip: `This property stores the Content Conversion Service Plan Name.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    conversionVersion: {
      defaultValue: '',
      placeHolder: 'Enter the Content Conversion Plan Version Number',
      tooltip: `This property stores the version of the Content Conversion Service Plan being used.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    conversionMimetype: {
      defaultValue: 'application/zip',
      placeHolder: 'Enter the MIME type of the input file',
      tooltip: `This property field stores the input file's MIME type.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    custom: {
      defaultValue: '',
      placeHolder: 'Enter any required key-value pairs',
      tooltip: `The property stores any key-value pairs needed by the Content Conversion Plan. Capture key-value pairs information in the following format:

key1=value1; key2=value2

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
  },
  'delete-service': {
    endpoint: {
      defaultValue: '',
      placeHolder: 'Enter the Delete Service endpoint',
      tooltip: `This property stores the Delete Service endpoint.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    documentURIs: {
      defaultValue: '',
      placeHolder: 'Enter the Document URIs',
      tooltip: `This property stores a comma-separated string containing the document URIs to delete.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    tenant: {
      defaultValue: '',
      placeHolder: 'Enter the Alfresco Tenant name',
      tooltip: `This property stores the Alfresco Tenant's name.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    alfrescoSite: {
      defaultValue: '',
      placeHolder: 'Enter the Alfresco Site name',
      tooltip: `This property stores the Alfresco Site from where documents will be deleted.

This property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    allegroRepository: {
      defaultValue: '',
      placeHolder: 'Enter the AllegroGraph Repository name',
      tooltip: `This property stores the AllegroGraph Repository name from where metadata will be deleted.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    failOnError: {
      defaultValue: 'true',
      placeHolder:
        "Enter 'true' or 'false' to determine the document's deletion status on 'error'",
      tooltip: `When a document returns an error, the Delete Service Module will fail or ignore the document's deletion status according to the value of this parameter.

  true = fail on error
  false = ignore on error

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    forceDelete: {
      defaultValue: 'false',
      placeHolder: "Enter 'true' to apply Force Delete",
      tooltip: `When a document's status in Alfresco is 'Locked', the value of this property determines what to do.

false = return a list of locked documents
true = check out all locked documents to allow them to be deleted.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    storeReport: {
      defaultValue: 'false',
      placeHolder: "Enter 'true' to generate a document status value report",
      tooltip: `When this property value is true, the Delete Service will create a report of document status values and save it into the DeleteReportHeader for further usage.
  
The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    deleteMetadata: {
      defaultValue: 'true',
      placeHolder: "Enter 'true' to delete the metadata",
      tooltip: `The Delete Service currently ignores this property, as content and metadata are deleted by default.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    deleteContent: {
      defaultValue: 'true',
      placeHolder: "Enter 'true' to delete the content",
      tooltip: `This Delete Service currently ignores this property, as content and metadata are deleted by default.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    deleteType: {
      defaultValue: '',
      placeHolder:
        'Enter the AllegroGraph delete type (HARD_DELETE or SOFT_DELETE)',
      tooltip: `This property determines how metadata is deleted from AllegroGraph

  HARD_DELETE = delete the graph from AllegroGraph.

  SOFT_DELETE = assign status = "statusDeleted" to the graph in AllegroGraph

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
  },
  delivery: {
    deliverTo: {
      defaultValue: 'S3',
      placeHolder: 'Enter the delivery destination type',
      tooltip: `This property determines the delivery destination type:

Direct path = delivers output to a local file system.

Path specified in dropZone
S3 = delivers to an S3 bucket
SFTP = delivers to SFTP
FTP = delivers to FTP
Azure Storage = delivers to Azure storage
AzureStorageAsync = delivers files to Azure Storage in parallel

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    customS3Endpoint: {
      defaultValue: '',
      placeHolder: 'Enter a custom S3 endpoint',
      tooltip: `This property stores a custom S3 endpoint URL if the default configuration of the current environment is insufficient.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    customRegion: {
      defaultValue: '',
      placeHolder: 'Enter the custom Region',
      tooltip: `This property stores the AWS Region to which content is delivered when the Custom S3 Endpoint is specified.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    bucket: {
      defaultValue: '',
      placeHolder: 'Enter the bucket name',
      tooltip: `This field contains the S3 bucket name/delivery path.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    accessKey: {
      defaultValue: '',
      placeHolder: 'Enter the AWS Access Key',
      tooltip: `This property stores the AWS Access Key.
The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    useDefaultProviderChain: {
      defaultValue: 'False',
      placeHolder: "Enter 'true' to use the DefaultAWSCredentialsProviderChain",
      tooltip: `This property determines which access credentials to retrieve:

false = use the access key and private key credentials
true = use DefaultAWSCredentialsProviderChain to get credentials

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    privateKey: {
      defaultValue: '',
      placeHolder: 'Enter the AWS Private Key',
      tooltip: `This property stores the AWS Private Key.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    dropZone: {
      defaultValue: '',
      placeHolder: 'Enter the Drop Zone',
      tooltip: `This property stores the Drop Zone path.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sftpDropZone: {
      defaultValue: '',
      placeHolder: 'Enter the SFTP Dropzone',
      tooltip: `This property stores the SFTP Dropzone path.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sftpPort: {
      defaultValue: '22',
      placeHolder: 'Enter the SFTP Port',
      tooltip: `This property stores the SFTP Port.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sftpLogin: {
      defaultValue: '',
      placeHolder: 'Enter the SFTP Login',
      tooltip: `This property stores the SFTP Login.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sftpPassword: {
      defaultValue: '',
      placeHolder: 'Enter the SFTP Password',
      tooltip: `This property stores the SFTP Password.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sftpPrivateKey: {
      defaultValue: '',
      placeHolder: 'Enter the SFTP Private Key',
      tooltip: `This property stores the SFTP Private Key, which is used with the sftpPassPhrase instead of the sftpPassword.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sftpPassPhrase: {
      defaultValue: '',
      placeHolder: 'Enter the SFTP Pass Phrase',
      tooltip: `This property stores the SFTP Pass Phrase.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    ftpHost: {
      defaultValue: '',
      placeHolder: 'Enter the FTP Host',
      tooltip: `This property stores the FTP Host path.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    ftpPort: {
      defaultValue: '22',
      placeHolder: 'Enter the FTP Port',
      tooltip: `This property stores the FTP Port.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    ftpFolder: {
      defaultValue: '',
      placeHolder: 'Enter the FTP Folder',
      tooltip: `This property stores the FTP Folder name on the server.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    ftpLogin: {
      defaultValue: '',
      placeHolder: 'Enter the FTP Login',
      tooltip: `This property stores the FTP Login credentials.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    ftpPassword: {
      defaultValue: '',
      placeHolder: 'Enter the FTP Password',
      tooltip: `This property stores the FTP Password.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    SASURI: {
      defaultValue: '',
      placeHolder: 'Enter the Azure Storage URI',
      tooltip: `This property stores the Azure Storage URI.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    sharedAccessSignatureURI: {
      defaultValue: '',
      placeHolder: 'Enter the Shared Access Signature URI.',
      tooltip: `This property stores the Azure Storage URI where AzureStorageAsync is used.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    blobItemMaxAge: {
      defaultValue: '',
      placeHolder: 'Enter the Blob item maximum age',
      tooltip: `This property specifies the Time To Live (TTL) value for an uploaded file in Azure Blob Storage. The values are integers and in seconds. If no value is set in the backend, the value is set as 864000 = 10 days.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    cntAttempts: {
      defaultValue: '1',
      placeHolder: 'Enter the maximum number of delivery attempts',
      tooltip: `This property specifies the number of delivery attempts in case of failure.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    secAttemptsGap: {
      defaultValue: '10',
      placeHolder: 'Enter the time gap between delivery attempts',
      tooltip: `This property specifies the interval in seconds between delivery attempts.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    deliveredFilesHeaderName: {
      defaultValue: '',
      placeHolder: 'Enter a bespoke delivered files header name, if needed',
      tooltip: `Where populated, this property specifies the Delivered Files Header Name to use in other modules.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
  },
  enrichment: {
    endpoint: {
      defaultValue: '',
      placeHolder: 'Enter the Content Enrichment endpoint',
      tooltip: `This property stores the Content Enrichment service endpoint URL.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    inputContentType: {
      defaultValue: 'application/xml',
      placeHolder: 'Enter the MIME type of the input file',
      tooltip: `This property stores the MIME type of the input file.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    inputMetadataType: {
      defaultValue: 'text/turtle',
      placeHolder: 'Enter the MIME type of the input metadata file',
      tooltip: `This property stores the MIME type of the input metadata file.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    outputContentType: {
      defaultValue: 'application/xml',
      placeHolder: 'Enter the MIME type of the output file',
      tooltip: `This property stores the MIME type of the output file.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    outputMetadataType: {
      defaultValue: 'application/rdf+xml',
      placeHolder: 'Enter the MIME type of the output metadata file',
      tooltip: `This property stores the MIME type of the output metadata file.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    enrichmentPlan: {
      defaultValue: '',
      placeHolder: 'Enter the name of the Enrichment Plan to use',
      tooltip: `This property stores the name of the Enrichment Plan for use by the Enrichment Service.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    enrichmentPlanVersion: {
      defaultValue: '',
      placeHolder: 'Enter the version of the Enrichment Plan to use',
      tooltip: `This property stores the version of the Enrichment Plan to use in the Enrichment Service.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    domain: {
      defaultValue: 'Enrichment',
      placeHolder: 'Enter the OSA Domain name',
      tooltip: `This property stores the OSA Domain Name. 'enrichment' is the only domain value option available.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    customProperties: {
      defaultValue: '',
      placeHolder: 'Enter the key-value pairs',
      tooltip: `This property stores any key-value pairs needed by the Enrichment Plan. Capture this information in the following format: key1=value1; key2=value2

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
  },
  export: {
    endpoint: {
      defaultValue: '',
      placeHolder: 'Enter the Export Service endpoint',
      tooltip: `This property stores the Export Service endpoint URL.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    tenant: {
      defaultValue: '',
      placeHolder: 'Enter the Alfresco Tenant name',
      tooltip: `This property stores the Tenant name, which is preconfigured on the Export Service side.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    provider: {
      defaultValue: 'alfresco',
      placeHolder: 'Enter the Provider name (Alfresco | AGraph)',
      tooltip: `This property stores the Export Service Provider name, which is preconfigured on the Export Service side. It can be Alfresco or AGraph.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    documents: {
      defaultValue: '',
      placeHolder: 'Enter document URIs to export',
      tooltip: `This property stores the list of comma-separated document URIs to export.

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    custom: {
      defaultValue: '',
      placeHolder: 'Enter the key-value pairs',
      tooltip: `The property stores any key-value pairs needed by the Export Service Plan. Capture key-value pairs information in the following format: key1=value1; key2=value2

The property's database constraint is MEDIUMTEXT (up to 16 MB).`,
    },
    packageInformation: {
      defaultValue: 'false',
      placeHolder: "Enter 'true' to include package info in CSB header",
      tooltip: `This property value determines whether information about the package is added to the CSB header.

true = Updates hasAssets CSB header if output PCI package contains attachments. Updates pciDocsCount CSB header with number of documents in the PCI output package.
Boolean

Updates hasAssets CSB header - sets true if output pci package contains attachments.

Updates pciDocsCount CSB header - sets number of documents in output pci package.`,
    },
  },
};

export const DESIGN_ORCHESTRATION_MODULES_PANEL_WIDTH = 328;
export const DESIGN_ORCHESTRATION_XML_PANEL_WIDTH = 368;
