import {
  ICreateModuleData,
  IModuleParameterItem,
} from 'interfaces/modules/module.interface';
import { Useuid } from 'utils/hooks/useUid';
import { XMLParser, XMLValidator } from 'fast-xml-parser';

export interface FormatModuleDataOptions {
  useDefaultEmptyString?: boolean;
}

const ERROR_MESSAGE = 'Invalid XML format';

const options = {
  ignoreAttributes: false,
  attributeNamePrefix: '',
  parseAttributeValue: true,
};

export const formatModuleData = (
  moduleData: Partial<ICreateModuleData>,
  moduleParameters: IModuleParameterItem[],
  options?: FormatModuleDataOptions
) => {
  const { useDefaultEmptyString = false } = options || {};

  const getValue = <T>(value: T | undefined): T | string | undefined => {
    if (useDefaultEmptyString) {
      return value ?? '';
    }
    return value;
  };

  return {
    name: getValue(moduleData.moduleName),
    textId: getValue(moduleData.artifactId),
    description: getValue(moduleData.description),
    author: {
      name: getValue(moduleData.authorName),
      email: getValue(moduleData.email),
    },
    parameters: moduleParameters
      .filter((param) => param.type)
      .map((param) => {
        const processedParam: any = {
          textId: getValue(param.paramId?.replace(/\s+/g, '')),
          type: getValue(param.type),
          name: getValue(param.paramName),
          required: param.isRequired ?? true,
        };

        if (param.type === 'ENUM' || param.type === 'MULTI') {
          processedParam.default = getValue(param.value);
          processedParam.value = getValue(param.values);
        } else if (param.value !== undefined && param.value !== '') {
          processedParam.default = param.value;
        } else if (useDefaultEmptyString) {
          processedParam.default = '';
        }

        if (param.dependsOn) {
          processedParam.dependsId = param.dependsOn;
          processedParam.dependsValue = getValue(param.enumValue);
        }

        return processedParam;
      }),
  };
};

export const parseParametersXML = async (
  xmlString: string
): Promise<IModuleParameterItem[] | undefined> => {
  const parseParameter = (
    param: any,
    parentParamId = '',
    enumValue = ''
  ): IModuleParameterItem[] => {
    let items: IModuleParameterItem[] = [];
    if (!param) {
      return items;
    }
    for (const [key, value] of Object.entries(param)) {
      const paramType = key.toUpperCase();
      const paramsArray = Array.isArray(value) ? value : [value];
      for (const paramItem of paramsArray) {
        const item: IModuleParameterItem = {
          id: Useuid(),
          paramId: paramItem.textId || '',
          paramName: paramItem.name || '',
          type: paramType,
          isRequired: paramItem.required,
          value:
            paramType === 'MULTI'
              ? Array.isArray(paramItem.default)
                ? paramItem.default.value.join('|')
                : paramItem.default.value || ''
              : paramItem.default || '',
          dependsOn: parentParamId,
          enumValue: paramItem.depends || enumValue,
          values: paramItem.values
            ? Array.isArray(paramItem.values.value)
              ? paramItem.values.value.join('|')
              : paramItem.values.value || ''
            : '',
        };
        items.push(item);

        if (paramItem.parameters) {
          const childParams = parseParameter(
            paramItem.parameters,
            item.paramId,
            paramItem.depends || ''
          );
          items = items.concat(childParams);
        }
      }
    }

    return items;
  };

  if (!xmlString) {
    console.error('No XML string provided');
    return undefined;
  }

  if (XMLValidator.validate(xmlString) === true) {
    // Valid XML, proceed to parse
    const parser = new XMLParser();
    const result = parser.parse(xmlString);
    const parameters = result.module?.metadata?.parameters;
    return parseParameter(parameters);
  } else {
    console.error(ERROR_MESSAGE);
  }
  return undefined;
};

export const parsePomXML = (
  xmlString: string
): Partial<ICreateModuleData> | undefined => {
  if (!xmlString) {
    console.error('No XML string provided');
    return undefined;
  }

  if (XMLValidator.validate(xmlString) !== true) {
    console.error(ERROR_MESSAGE);
    return undefined;
  }

  // Create a new XMLParser instance with options
  const parser = new XMLParser({
    ...options,
    removeNSPrefix: true, // Removes namespace prefixes
  });

  // Parse the XML string
  const result = parser.parse(xmlString);

  // Navigate to the project element
  const project = result.project;

  if (!project) {
    console.error('No <project> element found in pom.xml');
    return undefined;
  }

  // Extract the required properties with default values
  const artifactId = project.artifactId ?? '';
  const moduleName = project.name ?? '';
  const description = project.description ?? '';
  const groupId = project.groupId ?? '';
  const developers = project.developers?.developer;

  let authorName = '';

  if (developers) {
    const developer = Array.isArray(developers) ? developers[0] : developers;
    authorName = developer.name ?? '';
  }

  // Construct and return the moduleData object
  const moduleData: Partial<ICreateModuleData> = {
    moduleName,
    description,
    authorName,
    groupId,
    artifactId,
  };

  return moduleData;
};

export const sortParameters = (
  moduleParameters: IModuleParameterItem[],
  paramListData: IModuleParameterItem[]
): IModuleParameterItem[] => {
  const existingParamOrder = paramListData.map((param) => param.paramId);
  const moduleParametersOrdered: IModuleParameterItem[] = [];

  for (const paramId of existingParamOrder) {
    const matchedParam = moduleParameters.find((p) => p.paramId === paramId);
    if (matchedParam) {
      moduleParametersOrdered.push(matchedParam);
    }
  }

  const orderedParamIds = moduleParametersOrdered.map((p) => p.paramId);
  const newParams = moduleParameters.filter(
    (p) => !orderedParamIds.includes(p.paramId)
  );

  return [...moduleParametersOrdered, ...newParams];
};

export const findFirstGroupIdOutsideParent = (
  xmlString: string
): string | undefined => {
  if (XMLValidator.validate(xmlString) !== true) {
    console.error(ERROR_MESSAGE);
    return undefined;
  }

  const parser = new XMLParser({
    ...options,
    removeNSPrefix: true, // Removes namespace prefixes
  });

  const result = parser.parse(xmlString);

  const project = result.project;

  if (!project) {
    console.error('No <project> element found in pom.xml');
    return undefined;
  }

  return project.groupId;
};
