import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { StackTraceBanner } from 'components/StackTrace/StackTraceBanner/StackTraceBanner';
import { StackTraceEditor } from 'components/StackTrace/StackTraceEditor/StackTraceEditor';
import style from './StackTrace.module.scss';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { postViewLog } from 'api/orchestrations/orchestrationsThunk';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { ILogFromServer } from 'interfaces/logRow.interface';
import { OverlayComponent } from 'components/OverlayComponent/OverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';

const StackTrace = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get('jobId') || '';
  const stepName = queryParams.get('stepName') || '';
  const timestamp = queryParams.get('timestamp') || '';
  const index = queryParams.get('index') || '';
  const originalStartTime = queryParams.get('originalStartTime') || '';
  const [stackTrace, setStackTrace] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchLogInfo();
  }, []);

  const fetchLogInfo = async () => {
    try {
      const logInfo: ILogFromServer[] = await dispatch(
        postViewLog({
          CSBJobId: jobId,
          fromTimeCSB: originalStartTime,
        })
      )?.unwrap();
      setStackTrace(getStackTrace(logInfo));
    } catch (error) {
      consoleErrorMessage(error);
    }
  };

  const getStackTrace = (logInfo: ILogFromServer[]) => {
    const errorLogItem = logInfo[Number(index)];
    return errorLogItem?.stackTrace;
  };

  return (
    <div data-testid="StackTrace" className={style['container']}>
      <CsbErrorBoundary>
        <StackTraceBanner
          jobId={jobId}
          stepName={stepName}
          timestamp={timestamp}
        />
        {stackTrace ? (
          <StackTraceEditor stackTrace={stackTrace} />
        ) : (
          <OverlayComponent page={PAGE_OPTIONS.generalPage} />
        )}
      </CsbErrorBoundary>
    </div>
  );
};

export default StackTrace;
