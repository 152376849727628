import { RowNode } from 'ag-grid-enterprise';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';
import saveAs from 'file-saver';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestrationRow.interface';
import { DateUtils } from '../dateUtils/DateUtils';
import { addToast } from 'redux/toast/toastSlice';
import { Useuid } from 'utils/hooks/useUid';
import {
  IToastAdditionalData,
  ToastStatus,
  ToastTypes,
} from 'interfaces/toasts';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import {
  IPreSubmitWorkflow,
  ISubmitWorkflow,
} from 'interfaces/modals/configureAndRun.interface';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  IOrchestrationServer,
  IStepInformation,
} from 'interfaces/orchestration.interface';
import { Buffer } from 'buffer';
import { IModuleServer } from 'interfaces/modules/module.interface';

export const getNameFromEmail = (
  email: string | undefined = undefined,
  defaultName = 'Automated process'
) => {
  return email && !email.startsWith('local.csb.user')
    ? email
        .split('@')[0]
        .split('.')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : defaultName;
};

export const downloadLog = async (
  orchestration: IOrchestrationRow,
  downloadHook: any
) => {
  try {
    const response = await downloadHook({
      CSBJobId: orchestration.id,
      fromTimeCSB: orchestration.originalStartTime,
    }).unwrap();
    const blob = new Blob([response['data']], {
      type: response['contentType'],
    });
    const fileName = response['name'];
    saveAs(blob, fileName);
  } catch (error) {
    consoleErrorMessage(error);
  }
};

export function capitalizeFirstLetter(str: any) {
  if (typeof str !== 'string') {
    return str;
  }
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export const calculateJobDurationIfApplies = (
  job: any | IOrchestrationRow
): string => {
  if (job.status === ORCHESTRATION_STATUSES.PENDING) {
    return 'Pending';
  }
  if (job.status === ORCHESTRATION_STATUSES.STOPPING) {
    return 'Stopping';
  }
  if (job.status === ORCHESTRATION_STATUSES.STOPPED) {
    return 'Stopped early';
  }
  if (job.startTime && !job.endTime) {
    return job.duration;
  }
  if (job.startTime && job.endTime) {
    return DateUtils.getFormattedDiff(job.startTime, job.endTime) || '<1s';
  } else {
    return 'Pending';
  }
};

export const getApiRoute = (route: string) => {
  return `${process.env.REACT_APP_API_URL}/${route}`;
};

export const currentSetTimeout = (
  current: React.MutableRefObject<NodeJS.Timeout>,
  callbackFn: any,
  time: number
) => {
  current.current = setTimeout(callbackFn, time);
};

export const currentClearTimeout = (
  current: React.MutableRefObject<NodeJS.Timeout>
) => {
  clearTimeout(current.current);
};

export const isPropertyToBeMasked = (prop: string) => {
  const maskedNames = ['Password', 'Key'];
  return maskedNames.some((name) => prop?.includes(name));
};

export const maskValue = (value: string) => {
  return value.replace(/./g, '*');
};

export const updateSteps = (
  currentOrchestration: Partial<IOrchestrationServer | IOrchestrationRow>,
  newStep: IStepInformation
) => {
  const updatedSteps = currentOrchestration?.steps?.map(
    (step: IStepInformation) => {
      if (
        step?.workflowStepId?.toString() === newStep?.workflowStepId?.toString()
      ) {
        return {
          ...step,
          ...newStep,
          endTime:
            newStep.endTime === undefined ? step.endTime : newStep.endTime,
          jobStepParams:
            newStep.jobStepParams === undefined
              ? step.jobStepParams
              : newStep.jobStepParams,
        };
      } else {
        return step;
      }
    }
  );
  return {
    ...currentOrchestration,
    steps: updatedSteps,
  };
};

export const isObjectsAreEqualDeep = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !isObjectsAreEqualDeep(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const consoleErrorMessage = (error: any) => {
  let message = null;
  message =
    error.response?.data?.message ??
    error.response?.data ??
    error.data?.message ??
    error.data ??
    error.message ??
    error;

  if (/<\s*html/i.test(message)) {
    const h1Match = message.match(/<h1>(.*?)<\/h1>/i);
    const titleMatch = message.match(/<title>(.*?)<\/title>/i);
    const bodyMatch = message.match(/<body>(.*?)<\/body>/i);
    if (h1Match) {
      message = h1Match[1];
    } else if (titleMatch) {
      message = titleMatch[1];
    } else if (bodyMatch) {
      message = bodyMatch[1];
    }
  }
  console.error(message);
  if (typeof message !== 'string') {
    return null;
  }
  return message;
};

export function createFilename(files: File[]) {
  let fileName = '';
  for (let i = 0; i < files.length; i++) {
    fileName += files[i].name;
    if (i < files.length - 1) {
      fileName += ', ';
    }
  }
  return fileName;
}

export const showToastAndClose = (
  status: ToastStatus,
  type: ToastTypes,
  handleUserRequest: (...args: any) => void,
  dispatch: (...args: any) => void,
  additionalData?: IToastAdditionalData
) => {
  dispatch(
    addToast({
      id: Useuid(),
      status,
      type,
      additionalData,
    })
  );
  handleUserRequest(MODAL_ACTIONS.cancel);
};

export const combineStatus = (
  status: ORCHESTRATION_STATUSES
): ORCHESTRATION_STATUSES => {
  if (status === ORCHESTRATION_STATUSES.STOPPING) {
    return ORCHESTRATION_STATUSES.STOPPED;
  }
  if (status === ORCHESTRATION_STATUSES.FINISHED_ERROR) {
    return ORCHESTRATION_STATUSES.FINISHED;
  }

  return status;
};

export const formatJobRequest = (data: IPreSubmitWorkflow): ISubmitWorkflow => {
  if (!data) {
    return {} as any;
  }
  let jobRequest: ISubmitWorkflow = {
    name: data.name,
    productName: data.productName,
    data: {
      priority: data.priority ?? '',
    },
  };
  if (data.content && data.content?.length > 0) {
    jobRequest = {
      ...jobRequest,
      data: { ...jobRequest.data, content: data.content as any },
    };
  }
  if (data.parameters?.length && data.parameters?.length > 0) {
    jobRequest = getParamsFromJobRequest(jobRequest, data);
  } else {
    jobRequest = {
      ...jobRequest,
      data: { ...jobRequest.data, parameters: {} },
    };
  }
  if (
    data.s3content &&
    data.s3content.files &&
    data.s3content.files.length > 0
  ) {
    jobRequest = {
      ...jobRequest,
      data: { ...jobRequest.data, s3content: data.s3content },
    };
  }
  return jobRequest;
};

const getParamsFromJobRequest = (
  jobRequest: ISubmitWorkflow,
  data: IPreSubmitWorkflow
) => {
  const newParams = data.parameters.map((param: any) => {
    if (param && typeof param === 'object') {
      const filteredParam = Object.fromEntries(
        Object.entries(param).filter(([key, val]) => val !== '' && val !== null)
      );
      return Object.keys(filteredParam).length > 0 ? filteredParam : null;
    }
    return null;
  });
  jobRequest = {
    ...jobRequest,
    data: {
      ...jobRequest.data,
      parameters: newParams.reduce((acc: any, param: any, index: number) => {
        if (param !== null) {
          acc[index] = param;
        }
        return acc;
      }, {}),
    },
  };
  return jobRequest;
};

export const AddZeroPrefix = (number: number | string) => {
  return number
    ? !isNaN(Number(number)) && Number(number) < 10
      ? `0${number}`
      : number
    : '';
};

export const hasPermission = (
  userPermissions: any,
  requiredPermission: string[]
) => {
  if (!userPermissions) {
    return false;
  }
  for (const [permission, value] of Object.entries(userPermissions)) {
    if (
      requiredPermission.includes(permission) &&
      (value === 'Enable' || value === 'Allow')
    ) {
      return true;
    }
  }
  return false;
};

export const safeStringify = (obj: any) => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    console.error('Error while stringifying:', error);
    return '';
  }
};

export const safeParse = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error while parsing:', error);
    return null;
  }
};

export const getOrchestrationDuration = (
  startTime?: string,
  rowNode?: RowNode
) => {
  const utcTimeNow = format(
    utcToZonedTime(new Date(), 'UTC'),
    'M/d/yyyy, HH:mm:ss'
  );
  const returnedTime = DateUtils.getFormattedDiff(
    startTime,
    utcTimeNow,
    'd-h-m-s'
  );
  if (returnedTime && rowNode) {
    rowNode?.setData({ ...rowNode.data, duration: returnedTime });
  }
  return returnedTime;
};

export const checkAndScrollToItem = (querId: string, retries = 20) => {
  const selectedModuleHtml = document.querySelector(querId) as HTMLElement;
  if (selectedModuleHtml) {
    selectedModuleHtml.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  } else if (retries > 0) {
    setTimeout(() => checkAndScrollToItem(querId, retries - 1), 300);
  }
};

export const retryLazyLoad = (
  importFunction: () => Promise<any>,
  retries = 4,
  delay = 15000
) => {
  return new Promise((resolve, reject) => {
    const attemptLoad = (attemptsLeft: number) => {
      importFunction()
        .then(resolve)
        .catch((error) => {
          if (attemptsLeft === 0) {
            reject(error);
          } else {
            setTimeout(() => {
              attemptLoad(attemptsLeft - 1);
            }, delay);
          }
        });
    };
    attemptLoad(retries);
  });
};

export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
): Blob => {
  const byteCharacters = Buffer.from(b64Data, 'base64').toString('binary');
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const groupArrayByKey = <T, K extends keyof any>(
  array: T[] | null | undefined,
  getKey: (item: T) => K
): Record<K, T[]> => {
  if (array === null || array === undefined) {
    return {} as Record<K, T[]>;
  }

  return array?.reduce((accumulator, currentItem) => {
    const key = getKey(currentItem);
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(currentItem);
    return accumulator;
  }, {} as Record<K, T[]>);
};

export const removeDuplicatesByKey = <T, K extends keyof any>(
  array: T[] | null | undefined,
  getKey: (item: T) => K
): T[] => {
  const encounteredKeys: Set<K> = new Set();
  const result: T[] = [];
  if (!Array.isArray(array)) {
    return [];
  }

  for (const item of array) {
    const key = getKey(item);
    if (!encounteredKeys.has(key)) {
      encounteredKeys.add(key);
      result.push(item);
    }
  }

  return result;
};

export const getModuleVersions = (
  key: string,
  groupedByVersion: Record<string, IModuleServer[]>
) => {
  if (!groupedByVersion[key]) {
    return [];
  }
  return groupedByVersion[key]
    .map(({ version }) => version)
    .sort((a, b) => {
      const partsA = a.split('.').map(Number);
      const partsB = b.split('.').map(Number);

      const maxLength = Math.max(partsA.length, partsB.length);
      for (let i = 0; i < maxLength; i++) {
        const partA = partsA[i] || 0;
        const partB = partsB[i] || 0;

        if (partA > partB) {
          return -1;
        }
        if (partA < partB) {
          return 1;
        }
      }
      return 0; // versions are equal
    });
};
