import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import AceEditor from 'react-ace';
import ace from 'ace-builds';

interface IStackTraceEditor {
  stackTrace: string | undefined;
}

export const StackTraceEditor = ({ stackTrace }: IStackTraceEditor) => {
  return (
    <CsbErrorBoundary>
      <div data-testid="StackTraceEditor">
        {stackTrace && (
          <AceEditor
            mode="text"
            theme=""
            name="editor"
            width="100%"
            value={stackTrace}
            height="100vh"
            showPrintMargin={false}
            editorProps={{ $blockScrolling: true, readOnly: true }}
            setOptions={{ useWorker: false, wrap: true }}
            onLoad={(editor) => {
              editor.session.setFoldStyle('markbeginend');
              const Range = ace.require('ace/range').Range;
              const totalLines = editor.session.getLength();
              editor.session.addFold(
                'Error stack trace',
                new Range(30, 1, totalLines, 0)
              );
            }}
          />
        )}
      </div>
    </CsbErrorBoundary>
  );
};
